export default {
  root: {
    class: [
      // Misc
      'overflow-x-auto'
    ]
  },
  menu: {
    class: [
      // Flex & Alignment
      'flex items-center flex-nowrap',

      // Spacing
      'm-0 mb-8 p-0 list-none leading-none'
    ]
  },
  action: {
    class: [
      // Flex & Alignment
      'flex items-center',

      // Color
      'text-gray-400 text-sm',

      // States
      'focus-visible:outline-none focus-visible:outline-offset-0',
      'focus-visible:ring focus-visible:ring-primary-300/50',

      // Transitions
      'transition-shadow duration-200',

      // Misc
      'text-decoration-none'
    ]
  },
  icon: {
    class: 'text-gray-500'
  },
  separator: {
    class: [
      // Flex & Alignment
      'flex items-center',

      // Spacing
      ' mx-2',

      // Color
      'text-gray-600'
    ]
  }
}
