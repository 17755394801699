export default {
  root: ({ props, state }) => ({
    class: [
      // Display and Position
      'inline-flex',
      'relative',

      // Color and Background
      'form-control',
      'bg-gray-700',
      'shadow-sm shadow-black/50',
      'border',

      { 'border-white/5': !props.invalid },
      { 'border-red-900': props.invalid },

      // Transitions
      'transition-all',
      'duration-200',

      // States
      { 'hover:border-white/5': !props.invalid },
      { 'hover:border-red-900': props.invalid },
      { 'outline-none outline-offset-0 ring ring-inset ring-primary-300/50': state.focused },

      // Misc
      'cursor-pointer',
      'select-none',
      {
        'opacity-60': props.disabled,
        'pointer-events-none': props.disabled,
        'cursor-default': props.disabled
      }
    ]
  }),
  input: ({ props }) => ({
    class: [
      // Display
      'block',
      'flex-auto',
      'text-base leading-none',

      // Color and Background
      'bg-transparent',
      'border-0',
      {
        'text-white/80': props.modelValue != undefined,
        'text-surface-500': props.modelValue == undefined
      },
      'placeholder:text-surface-500',

      // Sizing and Spacing
      'w-[1%]',
      'p-3',
      { 'pr-7': props.showClear },

      //Shape
      'rounded-none',

      // Transitions
      'transition',
      'duration-200',

      // States
      'focus:outline-none focus:shadow-none',

      // Misc
      'relative',
      'cursor-pointer',
      'overflow-hidden overflow-ellipsis',
      'whitespace-nowrap',
      'appearance-none'
    ]
  }),
  trigger: {
    class: [
      // Flexbox
      'flex items-center justify-center',
      'shrink-0',

      // Color and Background
      'bg-transparent',
      'text-primary-500',

      // Size
      'w-12'
    ]
  },
  panel: {
    class: [
      // Position
      'absolute top-0 left-0',

      // Shape
      'border',
      'shadow-sm shadow-block',

      // Color
      'bg-gray-800',
      'text-white/80',
      'border-white/5',

      // Print
      'print:hidden'
    ]
  },
  wrapper: {
    class: [
      // Sizing
      'max-h-[200px]',

      // Misc
      'overflow-auto'
    ]
  },
  list: {
    class: 'list-none m-0'
  },
  item: ({ context }) => ({
    class: [
      // Position
      'relative',

      // Shape
      'border-t border-b first:border-t-none last:border-b-none',
      'rounded-none',

      // Spacing
      'm-0',
      'px-3 py-2.5',

      // Color
      { 'text-white/80': !context.focused && !context.selected && !context.disabled },
      { 'text-white/70': !context.focused && !context.selected && context.disabled },
      { 'bg-primary-800 text-white/80': context.focused && !context.selected },
      { 'bg-primary-600 text-white/80': context.focused && context.selected },
      { 'bg-primary-400/40 text-white/80': !context.focused && context.selected },
      { 'border-t-transparent border-b-black/25': context.disabled || !context.focused },
      { 'border-t-white/5 border-b-white/5': !context.disabled && context.focused },

      //States
      { 'hover:bg-primary-900': !context.focused && !context.selected },
      { 'hover:text-white hover:bg-primary-900': context.focused && !context.selected },
      'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring-inset focus-visible:ring-primary-300/50',

      // Transitions
      'transition-shadow',
      'duration-200',

      // Misc
      { 'pointer-events-none cursor-default focus:ring-0 focus:outline-none': context.disabled },
      { 'cursor-pointer': !context.disabled },
      'overflow-hidden',
      'whitespace-nowrap'
    ]
  }),
  itemgroup: {
    class: [
      //Font
      'font-bold',

      // Spacing
      'm-0',
      'py-3 px-5',

      // Color
      'text-white/80',
      'bg-surface-600/80',

      // Misc
      'cursor-auto'
    ]
  },
  emptymessage: {
    class: [
      // Font
      'leading-none',

      // Spacing
      'py-3 px-5',

      // Color
      'text-white/80',
      'bg-transparent'
    ]
  },
  header: {
    class: [
      // Spacing
      'py-3 px-5',
      'm-0',

      //Shape
      'border-b',

      // Color
      'text-white/80',
      'bg-surface-800',
      'border-surface-700'
    ]
  },
  filtercontainer: {
    class: 'relative'
  },
  filterinput: {
    class: [
      // Font
      'font-sans',
      'leading-none',

      // Sizing
      'pr-7 py-3 px-3',
      '-mr-7',
      'w-full',

      //Color
      'text-white/80',
      'bg-surface-900',
      'border-surface-700',

      // Shape
      'border',
      'appearance-none',

      // Transitions
      'transition',
      'duration-200',

      // States
      'hover:border-primary-300',
      'focus:ring focus:outline-none focus:outline-offset-0',
      'focus:ring-inset focus:ring-primary-300/50',

      // Misc
      'appearance-none'
    ]
  },
  filtericon: {
    class: ['absolute', 'top-1/2 right-3', '-mt-2']
  },
  clearicon: {
    class: [
      // Color
      'text-surface-500',

      // Position
      'absolute',
      'top-1/2',
      'right-12',

      // Spacing
      '-mt-2'
    ]
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass:
      'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0'
  }
}
