export default {
  root: {
    class: [
      'relative',

      // Alignment
      'inline-flex',
      'align-bottom',

      // Misc
      'cursor-pointer',
      'select-none'
    ]
  },
  box: ({ props }) => ({
    class: [
      // Alignments
      'items-center inline-flex flex-1 text-center align-bottom justify-center',

      // Sizes & Spacing
      'px-4 py-3 leading-none',

      // Shapes
      'border border-white/5',
      'shadow-sm shadow-black/50',

      // Colors
      {
        'bg-surface-700': !props.modelValue,
        'text-white/80': !props.modelValue,
        'bg-primary-400 border-primary-400 text-surface-900': props.modelValue
      },

      // States
      {
        'peer-hover:bg-surface-800/80 peer-hover:bg-surface-700 peer-hover:text-white/80':
          !props.modelValue,
        'peer-hover:border-primary-300': props.modelValue,
        'peer-focus-visible:ring peer-focus-visible:ring-inset peer-focus-visible:ring-primary-300/50':
          !props.disabled
      },

      // Transitions
      'transition-all duration-200',

      // Misc
      {
        'cursor-pointer': !props.disabled,
        'opacity-60 select-none pointer-events-none cursor-default': props.disabled
      }
    ]
  }),
  label: {
    class: 'font-fancy font-medium uppercase tracking-wide text-center w-full'
  },
  input: {
    class: [
      'peer',

      // Size
      'w-full ',
      'h-full',

      // Position
      'absolute',
      'top-0 left-0',
      'z-10',

      // Spacing
      'p-0',
      'm-0',

      // Shape
      'opacity-0',
      'outline-none',
      'border border-surface-700',

      // Misc
      'appearance-none',
      'cursor-pointer'
    ]
  },
  icon: ({ props }) => ({
    class: [
      ' mr-2',
      {
        'text-white/70': !props.modelValue,
        'text-surface-900': props.modelValue
      }
    ]
  })
}
