export default {
  root: {
    class: [
      'relative',

      // Flexbox & Alignment
      'inline-flex',
      'align-bottom',

      // Misc
      'cursor-pointer',
      'select-none'
    ]
  },
  box: ({ props }) => ({
    class: [
      // Flexbox
      'flex justify-center items-center',

      // Shape
      'border-4',
      'rounded-full',

      // Transition
      'transition duration-200 ease-in-out',

      // Colors
      {
        'text-white/80': props.value !== props.modelValue && props.value !== undefined,
        'bg-surface-900': props.value !== props.modelValue && props.value !== undefined,
        'border-surface-700': props.value !== props.modelValue && props.value !== undefined,
        'border-primary-400': props.value == props.modelValue && props.value !== undefined,
        'bg-primary-400': props.value == props.modelValue && props.value !== undefined
      },

      // States
      {
        'peer-hover:border-primary-400': !props.disabled,
        'peer-hover:border-primary-300 peer-hover:bg-primary-300':
          !props.disabled && props.value == props.modelValue && props.value !== undefined,
        'peer-focus-visible:border-primary-400 peer-focus-visible:ring-2 peer-focus-visible:ring-primary-300/20':
          !props.disabled,
        'opacity-60 cursor-default': props.disabled
      }
    ]
  }),
  input: {
    class: [
      'peer',

      // Size
      'w-full ',
      'h-full',

      // Position
      'absolute',
      'top-0 left-0',
      'z-10',

      // Spacing
      'p-0',
      'm-0',

      // Shape
      'opacity-0',
      'rounded-md',
      'outline-none',
      'border-2 border-surface-700',

      // Misc
      'appearance-none',
      'cursor-pointer'
    ]
  },
  icon: ({ props }) => ({
    class: [
      'block',

      // Shape
      'rounded-full',

      // Colors
      'bg-surface-900',

      // Conditions
      {
        'backface-hidden scale-10 invisible': props.value !== props.modelValue,
        'transform visible scale-[1.1]': props.value == props.modelValue
      },

      // Transition
      'transition duration-200'
    ]
  })
}
