export default {
  root: {
    class: [
      // Sizing and Shape
      'min-w-[12rem]',
      // Colors
      'bg-gray-800',
      'shadow-lg',
      'border border-white/5'
    ]
  },
  menu: {
    class: [
      // Spacings and Shape
      'list-none',
      'm-0',
      'p-0',
      'outline-none'
    ]
  },
  content: ({ context }) => ({
    class: [
      // Colors
      'border-t border-b last:border-b-none',

      // Color
      { 'text-white/80': !context.focused && !context.selected && !context.disabled },
      { 'text-white/40': !context.focused && !context.selected && context.disabled },
      { 'bg-primary-800 text-white/80': context.focused && !context.selected },
      { 'bg-primary-600 text-white/80': context.focused && context.selected },
      { 'bg-primary-400/40 text-white/80': !context.focused && context.selected },
      { 'border-t-transparent border-b-black/20': context.disabled || !context.focused },
      { 'border-t-white/5 border-b-white/5': !context.disabled && context.focused },

      //States
      {
        'hover:!text-white/40 hover:!bg-transparent': context.disabled,
        'hover:bg-primary-900': !context.disabled && !context.focused && !context.selected,
        'hover:text-white hover:bg-primary-900':
          !context.disabled && context.focused && !context.selected
      },

      'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring-inset focus-visible:ring-primary-300/50',

      // Transitions
      'transition-shadow',
      'duration-200'
    ]
  }),
  action: ({ context }) => ({
    class: [
      'relative',

      // Flexbox
      'flex',
      'items-center',

      // Spacing
      'px-3 py-2',

      // Misc
      'no-underline',
      'overflow-hidden',
      'cursor-pointer',
      'select-none',

      { 'focus:ring-0 focus:outline-none': context.disabled }
    ]
  }),
  icon: ({ context }) => ({
    class: [
      // Spacing
      'mr-2 min-w-3',

      // Size
      'h-3 w-3',

      // Color
      {
        'text-surface-400': !context.disabled,
        'text-white/40': context.disabled
      }
    ]
  }),
  label: {
    class: ['leading-none text-xs font-sans']
  },
  submenuheader: {
    class: [
      // Font
      'font-semibold font-sans text-xs',

      // Spacing
      'm-0',
      'p-2',

      // Colors
      'text-surface-300'
    ]
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass:
      'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0'
  }
}
