<script setup lang="ts">
import { inject } from 'vue'
import { onKeyStroke } from '@vueuse/core'
import Toast from 'primevue/toast'

// eslint-disable-next-line no-console
console.log(
  `%cNomic HQ v${inject('version')}`,
  'color: #0082F6; font-weight: bold; font-size: 16px; padding: 5px 0;'
)

// Global keybindings
onKeyStroke(['meta', 'k'], (e) => {
  e.preventDefault()
  document?.getElementById('site-search')?.focus()
})

onKeyStroke(['meta', 'o'], (e) => {
  e.preventDefault()
  document?.getElementById('org-picker-trigger')?.click()
})
</script>

<template>
  <Toast position="bottom-right" />
  <router-view />
</template>
