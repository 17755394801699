export default {
  root: ({ props }) => ({
    class: [
      'bg-gray-800 border border-white/5',
      { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled }
    ]
  }),
  button: ({ context, props }) => ({
    class: [
      'relative',
      // Font
      'leading-none',

      // Flex Alignment
      'inline-flex items-center align-bottom text-center',

      // Spacing
      'px-3 py-2 m-1',

      'border',

      // Color
      {
        'bg-gray-800': !context.active,
        'border-transparent text-white/70': !context.active,
        'border-white/5 bg-primary-500 text-surface-900': context.active,
        'border-red-900': props.invalid
      },

      // States
      'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-inset focus:ring-primary-300/50 focus:z-10',
      {
        'hover:text-white': !context.active,
        'hover:bg-primary-600': context.active
      },
      { 'opacity-60 select-none pointer-events-none cursor-default': context.disabled },
      // Transition
      'transition duration-200',

      // Misc
      'cursor-pointer select-none overflow-hidden'
    ]
  }),
  label: {
    class: 'font-semibold font-fancy uppercase tracking-wider'
  }
}
