export default {
  root: ({ props, context, parent }) => ({
    class: [
      // Font
      'font-sans leading-none text-base',

      // Flex
      { 'flex-1 w-[1%]': parent.instance.$name == 'InputGroup' },

      // Spacing
      'm-0',
      {
        'px-4 py-4': props.size == 'large',
        'px-2 py-2': props.size == 'small',
        'p-3': props.size == null
      },

      // Shape
      { 'border-0 border-y border-l last:border-r': parent.instance.$name == 'InputGroup' },
      { 'first:ml-0 ml-[-1px]': parent.instance.$name == 'InputGroup' && !props.showButtons },
      { 'shadow-sm shadow-black/50': !props.hideShadow },

      // Colors
      'text-surface-200',
      'placeholder:text-surface-500',
      'bg-gray-700',
      'border',

      { 'border-white/5': !props.invalid },
      { 'border-red-900': props.invalid },

      // States
      {
        'focus:outline-none focus:outline-offset-0 focus:ring-inset focus:ring-gray-500 focus:z-10':
          !context.disabled,
        'opacity-60 select-none pointer-events-none cursor-default': context.disabled
      },

      // Misc
      'appearance-none',
      'transition-colors duration-200'
    ]
  })
}
