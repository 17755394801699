export default {
  root: ({ props }) => ({
    class: [
      // Flexbox and Position
      'inline-flex',
      'relative',

      // Shape
      { 'shadow-sm shadow-black/50': props.raised }
    ]
  }),
  button: {
    root: ({ parent }) => ({
      class: [
        'relative print:hidden',

        'font-fancy font-normal antialiased uppercase',

        // Alignments
        'items-center inline-flex text-center align-bottom justify-center',

        // Sizes & Spacing
        'leading-[150%]',
        {
          'text-[14px] px-3 py-2': parent.props.size === null,
          'text-[11px] p-1': parent.props.size === 'small',
          'text-base py-2.5 px-3.5': parent.props.size === 'large'
        },
        {
          'min-w-12 p-0 py-3': parent.props.label == null && parent.props.icon !== null
        },

        // Shape
        'rounded-r-none',
        'border-r-0',
        { 'rounded-full': parent.props.rounded },

        // Link Button
        { 'text-primary-600 bg-transparent border-transparent': parent.props.link },

        // Plain Button
        {
          'text-white bg-gray-500 border border-gray-500':
            parent.props.plain && !parent.props.outlined && !parent.props.text
        },
        // Plain Text Button
        { 'text-surface-500': parent.props.plain && parent.props.text },
        // Plain Outlined Button
        { 'text-surface-500 border border-gray-500': parent.props.plain && parent.props.outlined },

        // Text Button
        { 'bg-transparent border-transparent': parent.props.text && !parent.props.plain },

        // Outlined Button
        { 'bg-transparent border': parent.props.outlined && !parent.props.plain },

        // --- Severity Buttons ---

        // Primary Button
        {
          'text-white':
            !parent.props.link &&
            parent.props.severity === null &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'bg-primary-500':
            !parent.props.link &&
            parent.props.severity === null &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'border border-white/5':
            !parent.props.link &&
            parent.props.severity === null &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        // Primary Text Button
        {
          'text-primary-400':
            parent.props.text && parent.props.severity === null && !parent.props.plain
        },
        // Primary Outlined Button
        {
          'text-primary-500 border border-primary-500 hover:bg-primary-300/20':
            parent.props.outlined && parent.props.severity === null && !parent.props.plain
        },

        // Secondary Button
        {
          'text-white':
            parent.props.severity === 'secondary' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'bg-gray-700':
            parent.props.severity === 'secondary' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'border border-white/5 !border-r !border-r-black/20':
            parent.props.severity === 'secondary' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        // Secondary Text Button
        {
          'text-surface-400':
            parent.props.text && parent.props.severity === 'secondary' && !parent.props.plain
        },
        // Secondary Outlined Button
        {
          'text-surface-500 border border-surface-500 hover:bg-surface-300/20':
            parent.props.outlined && parent.props.severity === 'secondary' && !parent.props.plain
        },

        // Success Button
        {
          'text-surface-900':
            parent.props.severity === 'success' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'bg-green-400':
            parent.props.severity === 'success' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'border border-green-400':
            parent.props.severity === 'success' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        // Success Text Button
        {
          'text-surface-400':
            parent.props.text && parent.props.severity === 'secondary' && !parent.props.plain
        },
        // Success Outlined Button
        {
          'text-green-500 border border-green-500 hover:bg-green-300/20':
            parent.props.outlined && parent.props.severity === 'success' && !parent.props.plain
        },

        // Info Button
        {
          'text-surface-900':
            parent.props.severity === 'info' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'bg-blue-400':
            parent.props.severity === 'info' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'border border-blue-400':
            parent.props.severity === 'info' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        // Info Text Button
        {
          'text-blue-400':
            parent.props.text && parent.props.severity === 'info' && !parent.props.plain
        },
        // Info Outlined Button
        {
          'text-blue-500 border border-blue-500 hover:bg-blue-300/20 ':
            parent.props.outlined && parent.props.severity === 'info' && !parent.props.plain
        },

        // Warning Button
        {
          'text-surface-900':
            parent.props.severity === 'warning' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'bg-orange-400':
            parent.props.severity === 'warning' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'border border-orange-400':
            parent.props.severity === 'warning' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        // Warning Text Button
        {
          'text-orange-400':
            parent.props.text && parent.props.severity === 'warning' && !parent.props.plain
        },
        // Warning Outlined Button
        {
          'text-orange-500 border border-orange-500 hover:bg-orange-300/20':
            parent.props.outlined && parent.props.severity === 'warning' && !parent.props.plain
        },

        // Help Button
        {
          'text-surface-900':
            parent.props.severity === 'help' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'bg-purple-400':
            parent.props.severity === 'help' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'border border-purple-400':
            parent.props.severity === 'help' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        // Help Text Button
        {
          'text-purple-400':
            parent.props.text && parent.props.severity === 'help' && !parent.props.plain
        },
        // Help Outlined Button
        {
          'text-purple-500 border border-purple-500 hover:bg-purple-300/20':
            parent.props.outlined && parent.props.severity === 'help' && !parent.props.plain
        },

        // Danger Button
        {
          'text-surface-900':
            parent.props.severity === 'danger' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'bg-red-400':
            parent.props.severity === 'danger' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'border border-red-400':
            parent.props.severity === 'danger' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        // Danger Text Button
        {
          'text-red-400':
            parent.props.text && parent.props.severity === 'danger' && !parent.props.plain
        },
        // Danger Outlined Button
        {
          'text-red-500 border border-red-500 hover:bg-red-300/20':
            parent.props.outlined && parent.props.severity === 'danger' && !parent.props.plain
        },

        // --- Severity Button States ---
        'focus:outline-none focus:outline-offset-0 focus:ring-inset',

        // Link
        { 'focus:ring-primary-300/50': parent.props.link },

        // Plain
        {
          'hover:bg-gray-600 hover:border-gray-600':
            parent.props.plain && !parent.props.outlined && !parent.props.text
        },
        // Text & Outlined Button
        {
          'hover:bg-surface-300/20':
            parent.props.plain && (parent.props.text || parent.props.outlined)
        },

        // Primary
        {
          'hover:bg-primary-700':
            !parent.props.link &&
            parent.props.severity === null &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        {
          'focus:ring-primary-300/50':
            !parent.props.link &&
            parent.props.severity === null &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        // Text & Outlined Button
        {
          'hover:bg-primary-300/20':
            (parent.props.text || parent.props.outlined) &&
            parent.props.severity === null &&
            !parent.props.plain
        },

        // Secondary
        {
          'hover:bg-gray-800':
            parent.props.severity === 'secondary' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        { 'focus:ring-gray-500/25': parent.props.severity === 'secondary' },
        // Text & Outlined Button
        {
          'hover:bg-surface-300/20':
            (parent.props.text || parent.props.outlined) &&
            parent.props.severity === 'secondary' &&
            !parent.props.plain
        },

        // Success
        {
          'hover:bg-green-300 hover:border-green-300':
            parent.props.severity === 'success' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        { 'focus:ring-green-300/50': parent.props.severity === 'success' },
        // Text & Outlined Button
        {
          'hover:bg-green-300/20':
            (parent.props.text || parent.props.outlined) &&
            parent.props.severity === 'success' &&
            !parent.props.plain
        },

        // Info
        {
          'hover:bg-blue-300 hover:border-blue-300':
            parent.props.severity === 'info' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        { 'focus:ring-blue-300/50': parent.props.severity === 'info' },
        // Text & Outlined Button
        {
          'hover:bg-blue-300/20':
            (parent.props.text || parent.props.outlined) &&
            parent.props.severity === 'info' &&
            !parent.props.plain
        },

        // Warning
        {
          'over:bg-orange-300 hover:border-orange-300':
            parent.props.severity === 'warning' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        { 'focus:ring-orange-300/50': parent.props.severity === 'warning' },
        // Text & Outlined Button
        {
          'hover:bg-orange-300/20':
            (parent.props.text || parent.props.outlined) &&
            parent.props.severity === 'warning' &&
            !parent.props.plain
        },

        // Help
        {
          'hover:bg-purple-300 hover:border-purple-300':
            parent.props.severity === 'help' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        { 'focus:ring-purple-300/50': parent.props.severity === 'help' },
        // Text & Outlined Button
        {
          'hover:bg-purple-300/20':
            (parent.props.text || parent.props.outlined) &&
            parent.props.severity === 'help' &&
            !parent.props.plain
        },

        // Warning
        {
          'hover:bg-red-300 hover:border-red-300':
            parent.props.severity === 'danger' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        { 'focus:ring-red-300/50': parent.props.severity === 'danger' },
        // Text & Outlined Button
        {
          'hover:bg-red-300/20':
            (parent.props.text || parent.props.outlined) &&
            parent.props.severity === 'danger' &&
            !parent.props.plain
        },

        // Transitions
        'transition duration-200 ease-in-out',

        // Misc
        'cursor-pointer overflow-hidden select-none'
      ]
    }),
    icon: {
      class: [
        'text-surface-500',

        // Margins
        'mr-2'
      ]
    }
  },
  menubutton: {
    root: ({ parent }) => ({
      class: [
        'relative',
        // Alignments
        'items-center inline-flex text-center align-bottom justify-center',

        // Sizes & Spacing
        'leading-[normal]',
        {
          'px-4 py-3': parent.props.size === null,
          'text-sm py-2 px-3': parent.props.size === 'small',
          'text-xl py-3 px-4': parent.props.size === 'large'
        },
        {
          'min-w-12 p-0 py-3': parent.props.label == null && parent.props.icon !== null
        },

        // Shape
        { 'rounded-full': parent.props.rounded },

        // Link Button
        { 'text-primary-600 bg-transparent border-transparent': parent.props.link },

        // Plain Button
        {
          'text-white bg-gray-500 border border-gray-500':
            parent.props.plain && !parent.props.outlined && !parent.props.text
        },
        // Plain Text Button
        { 'text-surface-500': parent.props.plain && parent.props.text },
        // Plain Outlined Button
        { 'text-surface-500 border border-gray-500': parent.props.plain && parent.props.outlined },

        // Text Button
        { 'bg-transparent border-transparent': parent.props.text && !parent.props.plain },

        // Outlined Button
        { 'bg-transparent border': parent.props.outlined && !parent.props.plain },

        // --- Severity Buttons ---

        // Primary Button
        {
          'text-surface-900':
            !parent.props.link &&
            parent.props.severity === null &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'bg-primary-500':
            !parent.props.link &&
            parent.props.severity === null &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'border border-white/5':
            !parent.props.link &&
            parent.props.severity === null &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        // Primary Text Button
        {
          'text-primary-400':
            parent.props.text && parent.props.severity === null && !parent.props.plain
        },
        // Primary Outlined Button
        {
          'text-primary-500 border border-primary-500 hover:bg-primary-300/20':
            parent.props.outlined && parent.props.severity === null && !parent.props.plain
        },

        // Secondary Button
        {
          'text-white':
            parent.props.severity === 'secondary' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'bg-gray-700':
            parent.props.severity === 'secondary' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'border border-white/5':
            parent.props.severity === 'secondary' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        // Secondary Text Button
        {
          'text-surface-400':
            parent.props.text && parent.props.severity === 'secondary' && !parent.props.plain
        },
        // Secondary Outlined Button
        {
          'text-surface-500 border border-surface-500 hover:bg-surface-300/20':
            parent.props.outlined && parent.props.severity === 'secondary' && !parent.props.plain
        },

        // Success Button
        {
          'text-surface-900':
            parent.props.severity === 'success' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'bg-green-400':
            parent.props.severity === 'success' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'border border-green-400':
            parent.props.severity === 'success' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        // Success Text Button
        {
          'text-surface-400':
            parent.props.text && parent.props.severity === 'secondary' && !parent.props.plain
        },
        // Success Outlined Button
        {
          'text-green-500 border border-green-500 hover:bg-green-300/20':
            parent.props.outlined && parent.props.severity === 'success' && !parent.props.plain
        },

        // Info Button
        {
          'text-surface-900':
            parent.props.severity === 'info' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'bg-blue-400':
            parent.props.severity === 'info' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'border border-blue-400':
            parent.props.severity === 'info' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        // Info Text Button
        {
          'text-blue-400':
            parent.props.text && parent.props.severity === 'info' && !parent.props.plain
        },
        // Info Outlined Button
        {
          'text-blue-500 border border-blue-500 hover:bg-blue-300/20 ':
            parent.props.outlined && parent.props.severity === 'info' && !parent.props.plain
        },

        // Warning Button
        {
          'text-surface-900':
            parent.props.severity === 'warning' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'bg-orange-400':
            parent.props.severity === 'warning' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'border border-orange-400':
            parent.props.severity === 'warning' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        // Warning Text Button
        {
          'text-orange-400':
            parent.props.text && parent.props.severity === 'warning' && !parent.props.plain
        },
        // Warning Outlined Button
        {
          'text-orange-500 border border-orange-500 hover:bg-orange-300/20':
            parent.props.outlined && parent.props.severity === 'warning' && !parent.props.plain
        },

        // Help Button
        {
          'text-surface-900':
            parent.props.severity === 'help' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'bg-purple-400':
            parent.props.severity === 'help' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'border border-purple-400':
            parent.props.severity === 'help' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        // Help Text Button
        {
          'text-purple-400':
            parent.props.text && parent.props.severity === 'help' && !parent.props.plain
        },
        // Help Outlined Button
        {
          'text-purple-500 border border-purple-500 hover:bg-purple-300/20':
            parent.props.outlined && parent.props.severity === 'help' && !parent.props.plain
        },

        // Danger Button
        {
          'text-surface-900':
            parent.props.severity === 'danger' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'bg-red-400':
            parent.props.severity === 'danger' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain,
          'border border-red-400':
            parent.props.severity === 'danger' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        // Danger Text Button
        {
          'text-red-400':
            parent.props.text && parent.props.severity === 'danger' && !parent.props.plain
        },
        // Danger Outlined Button
        {
          'text-red-500 border border-red-500 hover:bg-red-300/20':
            parent.props.outlined && parent.props.severity === 'danger' && !parent.props.plain
        },

        // --- Severity Button States ---
        'focus:outline-none focus:outline-offset-0 focus:ring-inset',

        // Link
        { 'focus:ring-primary-300/50': parent.props.link },

        // Plain
        {
          'hover:bg-gray-600 hover:border-gray-600':
            parent.props.plain && !parent.props.outlined && !parent.props.text
        },
        // Text & Outlined Button
        {
          'hover:bg-surface-300/20':
            parent.props.plain && (parent.props.text || parent.props.outlined)
        },

        // Primary
        {
          'hover:bg-primary-300':
            !parent.props.link &&
            parent.props.severity === null &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        {
          'focus:ring-primary-300/50':
            !parent.props.link &&
            parent.props.severity === null &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        // Text & Outlined Button
        {
          'hover:bg-primary-300/20':
            (parent.props.text || parent.props.outlined) &&
            parent.props.severity === null &&
            !parent.props.plain
        },

        // Secondary
        {
          'hover:bg-gray-800':
            parent.props.severity === 'secondary' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        { 'focus:ring-gray-500/25': parent.props.severity === 'secondary' },
        // Text & Outlined Button
        {
          'hover:bg-surface-300/20':
            (parent.props.text || parent.props.outlined) &&
            parent.props.severity === 'secondary' &&
            !parent.props.plain
        },

        // Success
        {
          'hover:bg-green-300':
            parent.props.severity === 'success' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        { 'focus:ring-green-300/50': parent.props.severity === 'success' },
        // Text & Outlined Button
        {
          'hover:bg-green-300/20':
            (parent.props.text || parent.props.outlined) &&
            parent.props.severity === 'success' &&
            !parent.props.plain
        },

        // Info
        {
          'hover:bg-blue-300':
            parent.props.severity === 'info' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        { 'focus:ring-blue-300/50': parent.props.severity === 'info' },
        // Text & Outlined Button
        {
          'hover:bg-blue-300/20':
            (parent.props.text || parent.props.outlined) &&
            parent.props.severity === 'info' &&
            !parent.props.plain
        },

        // Warning
        {
          'hover:bg-orange-300':
            parent.props.severity === 'warning' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        { 'focus:ring-orange-300/50': parent.props.severity === 'warning' },
        // Text & Outlined Button
        {
          'hover:bg-orange-300/20':
            (parent.props.text || parent.props.outlined) &&
            parent.props.severity === 'warning' &&
            !parent.props.plain
        },

        // Help
        {
          'hover:bg-purple-300':
            parent.props.severity === 'help' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        { 'focus:ring-purple-300/50': parent.props.severity === 'help' },
        // Text & Outlined Button
        {
          'hover:bg-purple-300/20':
            (parent.props.text || parent.props.outlined) &&
            parent.props.severity === 'help' &&
            !parent.props.plain
        },

        // Warning
        {
          'hover:bg-red-300':
            parent.props.severity === 'danger' &&
            !parent.props.text &&
            !parent.props.outlined &&
            !parent.props.plain
        },
        { 'focus:ring-red-300/50': parent.props.severity === 'danger' },
        // Text & Outlined Button
        {
          'hover:bg-red-300/20':
            (parent.props.text || parent.props.outlined) &&
            parent.props.severity === 'danger' &&
            !parent.props.plain
        },

        // Transitions
        'transition duration-200 ease-in-out',

        // Misc
        'cursor-pointer overflow-hidden select-none'
      ]
    }),
    label: {
      class: ['hidden']
    }
  },
  menu: {
    root: {
      class: [
        // Size
        'rounded-none',

        // Spacings and Shape
        'list-none',
        'm-0 !mt-2',
        'px-0 py-2',
        'outline-none',

        // Colors
        'bg-gray-800',
        'border border-white/5',
        'shadow-sm shadow-black/50',

        // Print
        'print:hidden'
      ]
    },
    menuitem: {
      class: [
        // Position
        'relative'
      ]
    },
    content: ({ context }) => ({
      class: [
        // Font
        'font-fancy font-medium uppercase',
        'tracking-wide leading-none text-sm',

        // Shape
        'border-t border-b first:border-t-none last:border-b-none',
        {
          'border-t-transparent border-b-transparent': context.disabled || !context.focused,
          'hover:border-t-white/5 hover:border-b-white/5': !context.disabled,
          'bg-primary-800 border-t-white/5 border-b-white/5': context.focused && !context.disabled
        },

        //  Colors
        {
          'text-gray-500': context.disabled,
          'text-white/70': !context.focused && !context.active && !context.disabled,
          'text-white/70 bg-primary-800': context.focused && !context.active && !context.disabled,
          'text-white/80 bg-primary-800': context.focused && context.active && !context.disabled,
          'text-white/80 bg-primary-800': !context.focused && context.active && !context.disabled
        },

        // Hover States
        { 'hover:bg-primary-900': !context.disabled },

        // Transitions
        'transition-shadow',
        'duration-200'
      ]
    }),
    action: ({ context }) => ({
      class: [
        'relative',

        { 'focus:ring-0 focus:outline-none': context.disabled },

        // Flexbox
        'flex',
        'items-center',

        // Spacing
        'px-3 p-1.5',

        // Misc
        'no-underline',
        'overflow-hidden',
        { 'cursor-pointer': !context.disabled },
        'select-none'
      ]
    }),
    icon: {
      class: [
        // Spacing
        'mr-2',

        'text-surface-500'
      ]
    },
    label: {
      class: ['leading-none']
    },
    submenuicon: {
      class: [
        // Position
        'ml-auto'
      ]
    },
    submenu: {
      class: [
        // Size
        'w-full sm:w-48',

        // Spacing
        'py-1',
        'm-0',
        'list-none',

        // Shape
        'shadow-none sm:shadow-sm shadow-black/50',
        'border-0',

        // Position
        'static sm:absolute',
        'z-10',

        // Color
        'bg-surface-700'
      ]
    },
    separator: {
      class: 'border-t border-surface-600 my-1'
    }
  }
}
