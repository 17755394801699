export default {
  root: ({ props, parent }) => ({
    class: [
      // Font
      'font-fancy font-semibold uppercase',
      {
        'text-xl': props.size == 'large',
        'text-2xl': props.size == 'xlarge'
      },

      // Alignments
      'inline-flex items-center justify-center',
      'relative',

      // Sizes
      {
        'h-8 w-8': props.size == null || props.size == 'normal',
        'w-12 h-12': props.size == 'large',
        'w-16 h-16': props.size == 'xlarge'
      },
      { '-ml-4': parent.instance.$style?.name == 'avatargroup' },

      // Shapes
      {
        'rounded-lg': props.shape == 'square',
        'rounded-full': props.shape == 'circle'
      },
      { 'border-2': parent.instance.$style?.name == 'avatargroup' },

      // Colors
      'text-white hover:text-white',
      'bg-primary-500 hover:bg-primary-700',
      'border border-white/5 hover:border-white/5',
      {
        'border-surface-800': parent.instance.$style?.name == 'avatargroup'
      }
    ]
  }),
  image: {
    class: 'h-full w-full'
  }
}
