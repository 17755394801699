export default {
  root: {
    class: 'overflow-x-auto'
  },
  menu: {
    class: [
      // Flexbox
      'flex flex-1',

      // Spacing
      'list-none',
      'p-0 m-0',

      // Colors
      'border-b-2 border-surface-700',
      'text-surface-0/80'
    ]
  },
  menuitem: {
    class: 'mr-0'
  },
  action: ({ context, state }) => ({
    class: [
      'relative',

      // Font
      'font-semibold tracking-wide text-sm',

      // Flexbox and Alignment
      'flex items-center',

      // Spacing
      'px-8 py-3',
      '-mb-[2px]',

      // Shape
      'border-b-2',

      // Colors and Conditions
      {
        'border-surface-700': state.d_activeIndex !== context.index,
        'text-surface-0/80': state.d_activeIndex !== context.index,

        'border-b-primary-400': state.d_activeIndex === context.index,
        'text-primary-400': state.d_activeIndex === context.index
      },

      // States
      'focus:outline-none focus:ring-0',
      {
        'hover:bg-surface-800/80': state.d_activeIndex !== context.index,
        'hover:border-primary-400': state.d_activeIndex !== context.index,
        'hover:text-surface-0': state.d_activeIndex !== context.index,
        'hover:text-primary-500 hover:border-primary-500': state.d_activeIndex === context.index
      },

      // Transitions
      'transition-all duration-200',

      // Misc
      'cursor-pointer select-none text-decoration-none',
      'overflow-hidden',
      'user-select-none'
    ]
  }),
  icon: {
    class: 'mr-2 text-surface-400'
  }
}
