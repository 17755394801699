import { createAuth0 } from '@auth0/auth0-vue'

const audience = import.meta.env.VITE_AUTH0_AUDIENCE
const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID
const domain = import.meta.env.VITE_AUTH0_DOMAIN
const logoutUri = import.meta.env.VITE_AUTH0_LOGOUT_URI
const redirectUri = import.meta.env.VITE_AUTH0_REDIRECT_URI

export const auth0 = createAuth0({
  domain: domain,
  clientId: clientId,
  authorizationParams: {
    redirect_uri: redirectUri,
    audience
  },
  useRefreshTokens: true
})

export const logout = async () =>
  await auth0.logout({
    logoutParams: { returnTo: logoutUri }
  })

export const setAccessToken = async (config) => {
  try {
    const accessToken = await auth0.getAccessTokenSilently()
    config.headers.Authorization = `Bearer ${accessToken}`
  } catch (error) {
    logout()
  }
  return config
}
