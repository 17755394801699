export default {
  root: ({ context, props }) => ({
    class: [
      // Font
      'font-sans leading-snug',

      // Spacing
      'm-0',
      'p-3',

      // Colors
      'text-surface-200',
      'placeholder:text-surface-500',
      'bg-surface-900',
      'border',

      { 'border-surface-600': !props.invalid },

      // Invalid State
      { 'border-red-900': props.invalid },

      // States
      {
        'hover:border-primary-400': !context.disabled,
        'focus:outline-none focus:outline-offset-0 focus:ring-inset focus:ring-primary-400/50':
          !context.disabled,
        'opacity-60 select-none pointer-events-none cursor-default': context.disabled
      },

      // Misc
      'appearance-none',
      'transition-colors duration-200'
    ]
  })
}
