export default {
  root: ({ props }) => ({
    class: [
      // Spacing and Shape
      'border-solid border-0 border-l-[6px]',

      // Colors
      {
        'bg-blue-500/20': props.severity == 'info',
        'bg-green-500/20': props.severity == 'success',
        'bg-orange-500/20': props.severity == 'warn',
        'bg-red-500/20': props.severity == 'error'
      },
      {
        'border-blue-400': props.severity == 'info',
        'border-green-400': props.severity == 'success',
        'border-orange-400': props.severity == 'warn',
        'border-red-400': props.severity == 'error'
      },
      {
        'text-blue-300': props.severity == 'info',
        'text-green-300': props.severity == 'success',
        'text-orange-300': props.severity == 'warn',
        'text-red-300': props.severity == 'error'
      }
    ]
  }),
  wrapper: {
    class: [
      // Flexbox
      'flex items-center',

      // Spacing
      'py-2 px-7'
    ]
  },
  icon: {
    class: [
      // Sizing and Spacing
      'w-4 h-4',
      'text-lg leading-none mr-2 shrink-0'
    ]
  },
  text: {
    class: [
      // Font and Text
      'text-sm leading-none',
      'font-medium'
    ]
  },
  button: {
    class: [
      // Flexbox
      'flex items-center justify-center',

      // Size
      'w-4 h-4',

      // Spacing and Misc
      'ml-auto  relative',

      // Shape
      'rounded-full',

      // Colors
      'bg-transparent',

      // Transitions
      'transition duration-200 ease-in-out',

      // States
      'hover:bg-surface-0/10',

      // Misc
      'overflow-hidden'
    ]
  },
  transition: {
    enterFromClass: 'opacity-0',
    enterActiveClass: 'transition-opacity duration-300',
    leaveFromClass: 'max-h-40',
    leaveActiveClass: 'overflow-hidden transition-all duration-300 ease-in',
    leaveToClass: 'max-h-0 opacity-0 !m-0'
  }
}
