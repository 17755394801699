export default {
  root: ({ props }) => ({
    class: [
      'overflow-hidden',
      'm-0 p-0',
      {
        'animate-pulse': props.animation !== 'none'
      },

      // Round
      { 'rounded-full': props.shape === 'circle' },

      // Colors
      'bg-surface-700'
    ]
  })
}
