export default {
  root: {
    class: [
      'relative',

      // Alignment
      'inline-flex',
      'align-bottom',

      // Size
      'w-6',
      'h-6',

      // Misc
      'cursor-pointer',
      'select-none'
    ]
  },
  box: ({ props, context }) => ({
    class: [
      // Alignment
      'flex',
      'items-center',
      'justify-center',

      // Size
      'w-6',
      'h-6',

      // Shape
      'border-2',

      // Colors
      {
        'border-surface-700 bg-surface-900': !context.checked,
        'border-primary-500 bg-primary-500': context.checked
      },

      // States
      {
        'peer-hover:border-primary-400': !props.disabled && !context.checked,
        'peer-hover:bg-primary-500 peer-hover:border-primary-500':
          !props.disabled && context.checked,
        'peer-focus-visible:border-primary-400 peer-focus-visible:ring-2 peer-focus-visible:ring-primary-300/20':
          !props.disabled,
        'cursor-default opacity-60': props.disabled
      },

      // Invalid State
      { 'border-red-900': props.invalid },

      // Transitions
      'transition-colors',
      'duration-200'
    ]
  }),
  input: {
    class: [
      'peer',

      // Size
      'w-full ',
      'h-full',

      // Position
      'absolute',
      'top-0 left-0',
      'z-10',

      // Spacing
      'p-0',
      'm-0',

      // Shape
      'opacity-0',
      'outline-none',
      'border-2 border-surface-700',

      // Misc
      'appareance-none',
      'cursor-pointer'
    ]
  },
  icon: {
    class: [
      // Font
      'text-base leading-none',

      // Size
      'w-4',
      'h-4',

      // Colors
      'text-surface-900',

      // Transitions
      'transition-all',
      'duration-200'
    ]
  }
}
