export default {
  root: {
    class: [
      //Shape
      'shadow-sm shadow-black print:shadow-transparent ',

      //Color
      'border border-white/5',
      'bg-surface-800 print:bg-transparent',
      'text-surface-0'
    ]
  },
  body: {
    class: 'p-5'
  },
  title: {
    class: 'v-heading-sm mb-2'
  },
  subtitle: {
    class: [
      //Font
      'font-normal',

      //Spacing
      'mb-2',

      //Color
      'text-surface-0/60'
    ]
  },
  content: {
    class: 'py-5'
  },
  footer: {
    class: 'pt-5'
  }
}
